<template>
  <v-container id="user-profile" fluid tag="section">
    <invite-user-dialog
      v-model="showInviteUser"
      :organisation-id="currentUser.organisationId"
      width="500"
      @show-message="showMessage"
      @close-invite-user-dialog="() => (showInviteUser = false)"
    />
    <v-overlay :value="overlay"></v-overlay>
    <v-row align="center" justify="center">
      <v-col cols="12" md="8" class="mb-16" style="padding-bottom: 150px;">
        <base-material-card
          class="v-card-profile"
          :avatar="avatarImage"
          :on-click="onAvatarClicked"
          :avatar-tooltip="avatarTooltip"
        >
          <v-card-text class="text-center">
            <v-container>
              <v-row dense>
                <v-col cols="12">
                  <h6 class="text-h4 mb-1 grey--text">YOUR ACCOUNT</h6>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col cols="12">
                  <h4 class="text-h3 font-weight-light mb-3 black--text">
                    {{ user.user.displayName }}
                  </h4>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col cols="12">
                  <p class="font-weight-light grey--text">
                    Here you can see and manage your account information
                    <br />as well as manage your subscription and <br />view
                    your remaining credits.
                  </p>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col cols="12">
                  <v-text-field
                    v-model="user.user.email"
                    outlined
                    readonly
                    label="Email"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row v-show="debug" dense>
                <v-col cols="12">
                  <v-text-field
                    v-model="user.user.uid"
                    readonly
                    label="User Id"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row v-if="user.roles === 'orgAdmin' || user.roles === 'orgUser'" dense>
                <v-col cols="12">
                  <!-- v-show="organisationList" -->
                  <v-text-field
                    v-model="currentOrganisation.name"
                    outlined
                    readonly
                    label="Organisation"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row v-show="user.roles" dense>
                <v-col cols="12">
                  <v-text-field
                    v-model="user.roles"
                    outlined
                    readonly
                    label="Role"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row v-show="user.subscriptions" dense>
                <v-col cols="12">
                  <v-text-field
                    v-model="subscriptions"
                    outlined
                    readonly
                    label="Active Subscription"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row v-show="user.subscriptions && !(user.roles == 'orgAdmin' || user.roles == 'orgUser')" dense>
                <v-col cols="12">
                  <v-text-field
                    v-model="user.subscriptions"
                    outlined
                    readonly
                    label="Current Subscription"
                  ></v-text-field>
                  <v-text-field
                    v-show="(user.roles == 'orgAdmin' || user.roles == 'orgUser')"
                    value="Organisation Subscription plan"
                    outlined
                    readonly
                    label="Current Subscription"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col cols="12">
                  <v-text-field
                    v-show="(user.roles !== 'orgAdmin' && user.roles !== 'orgUser')"
                    v-model="creditValue"
                    outlined
                    readonly
                    label="Monthly Credits Remaining"
                  ></v-text-field>
                  <v-text-field
                    v-show="(user.roles == 'orgAdmin')"
                    v-model="creditValue"
                    outlined
                    readonly
                    label="Credits Remaining"
                  ></v-text-field>
                </v-col>
              </v-row>

              <div>
                <v-file-input
                v-show="false"
                ref="imageField"
                v-model="uploadedFile"
                accept="image/*"
                outlined
                prepend-icon="mdi-camera"
                @change="uploadImage"
                @click:clear="clearUploadedImage"
              ></v-file-input>
              <image-cropper-dialog
                ref="cropperDialog"
                :uploaded-image="uploadedImage"
                :on-save-image="saveUploadedImage"
                @onCrop="(croppedImage) => {
                  avatarImage = croppedImage;
                }"
                @cancel-avatar-upload="clearUploadedImage()"
              />
              </div>

              <v-row v-show="false" dense>
                <v-col cols="12">
                  <v-text-field
                    v-show="(user.roles === 'orgUser')"
                    v-model="generatedValue"
                    outlined
                    readonly
                    label="Report Generated"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col cols="4">
                  <v-checkbox
                    v-model="user.user.emailVerified"
                    label="Email Verified"
                    readonly
                  ></v-checkbox>
                </v-col>
                <v-col cols="1">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-if="!user.user.emailVerified"
                        class="mt-4 primary"
                        alt="Resend Verification Email?"
                        v-bind="attrs"
                        icon
                        small
                        v-on="on"
                        @click="verifyEmail(user.user)"
                      >
                        <v-icon>mdi-refresh</v-icon>
                      </v-btn>
                    </template>
                    <span>Resend Verification Email?</span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-container>

            <v-divider v-if="user.roles === 'orgAdmin'"></v-divider>

            <v-card-subtitle v-if="user.roles === 'orgAdmin'">Organisation Users</v-card-subtitle>
            <v-snackbar
                  v-model="snackbar"
                  :color="snackbarColor"
                >
                  {{ message }}

                  <template v-slot:action="{ attrs }">
                    <v-btn
                      color="white"
                      text
                      v-bind="attrs"
                      @click="snackbar = false"
                    >
                      Close
                    </v-btn>
                  </template>
                </v-snackbar>
            <v-data-table v-if="user.roles === 'orgAdmin'"
              :headers="headers"
              :search="search"
              :items="usersList">
              <!-- :options.sync="options" -->
              <!-- :server-items-length="totalUsers" -->
              <!-- :loading="userLoading" -->
              <!-- > -->

              <v-divider inset></v-divider>
              <template v-slot:top>
                <v-toolbar flat color="white">
                  <div class="d-flex w-200">
                    <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      label="Filter"
                      dense
                      outlined
                      single-line
                      hide-details
                    ></v-text-field>
                    <v-btn color="primary" class="ml-2 white--text" @click="showInviteUser=true">
                      <v-icon dark>mdi-plus</v-icon>Invite User
                    </v-btn>
                  </div>
                </v-toolbar>
              </template>

              <template v-slot:[`item.displayName`]="{ item }">
                <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span
                    v-bind="attrs"
                    v-on="on"
                  >{{item.displayName}}</span>
                </template>
                <span>UID: {{item.id}}</span>
              </v-tooltip>
                <!-- <span>{{ getUserName(item.id) }}</span> -->
              </template>

              <template v-slot:[`item.actions`]="{ item }">
                <span v-if="item.email === user.user.email">Current User</span>

                <v-tooltip v-if="item.email != user.user.email" top>
                  <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        color="primary"
                        class="action-btn"
                        v-bind="attrs"
                        v-on="on"
                        @click="deleteOrganisationUser(item)"
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                  </template>
                  <span>Delete User</span>
                </v-tooltip>

              </template>
            </v-data-table>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions class="pa-7">
            <v-row align="center" justify="space-around">
              <v-btn
                v-if="user.roles !== 'orgAdmin' && user.roles !== 'orgUser'"
                v-show="!user.subscriptions"
                color="primary"
                class="mr-0"
                to="/pages/subscribe"
              >
                Add a Subscription
              </v-btn>

              <!-- to="/pages/subscribe?showRoles=oneoff" -->
              <v-btn
                v-if="user.roles !== 'orgAdmin' && user.roles !== 'orgUser'"
                v-show="
                  !user.subscriptions ||
                  (false && user.subscriptions && user.credits <= 0)
                "
                class="mr-0"
                to="/pages/subscribe"
              >
                Purchase a Report
              </v-btn>

              <v-btn
                v-show="user.subscriptions"
                :loading="loading"
                :disabled="loading"
                color="primary"
                class="mr-0"
                @click="openStripePortal()"
              >
                Manage Your Subscription
                <v-icon right> mdi-open-in-new </v-icon>
              </v-btn>
            </v-row>
          </v-card-actions>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/functions";
import "firebase/storage";
import defaultAvatarImage from"../../../../public/images/default-avatar.png";

const auth = firebase.auth();
const db = firebase.firestore();
const storageRef = firebase.storage().ref();

const USERS = "users";
const ORGANISATIONS = "organisations";

const isRunningWithEmulator = !!(
  process.env.VUE_APP_USE_LOCAL_EMULATORS === "true" &&
  location.hostname === "localhost"
);

window.Stripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY);

export default {
  name: "Profile",
  components: {
    InviteUserDialog: () => import("@/components/users/InviteUserDialog"),
    ImageCropperDialog: () => import("./ImageCropperDialog.vue"),
  },
  data() {
    return {
      debug: false,
      loader: null,
      loading: false,
      overlay: false,
      myUser: null,
      search: null,
      currentUser: {},
      currentOrganisation: {},
      organisationList: [],
      showInviteUser: false,
      snackbar: false,
      snackbarColor: "primary",
      message: "",
      headers: [
        // {
        //   text: "User ID",
        //   value: "id",
        // },
        {
          text: "Name",
          value: "displayName",
          sortable: false
        },
        {
          text: "User Email",
          value: "email",
        },
        {
          text: "Reports Generated",
          value: "reportsGenerated",
        },
        {
          text: "Actions",
          value: "actions",
        },
      ],
      usersList: [],
      adminUserList: {},
      users: [],
      totalUsers: 0,
      userLoading: true,
      options: {},
      boundUserList: [],
      cu: {},
      co: {},
      ou: [],
      avatarImage: defaultAvatarImage,
      avatarTooltip: 'Click on the image to update avatar',
      uploadedImage: null,
      uploadedFile: null,
    };
  },

  computed: {
    ...mapState(["user"]),
    subscriptions() {
      return this.user.subscriptions ? "Yes" : "No";
    },
    creditValue() {
      let credits = "";
      if (this.user.roles !== "business") {
        credits = this.user.credits ? this.user.credits : 0;
      } else {
        credits = "Unlimited";
      }
      return credits;
    },
    generatedValue() {
      const count = 0;
      return count;
    }
  },

  watch: {
    // currentUser: {
    //   // call it upon creation too
    //   immediate: true,
    //   handler(theCurrentUser) {
    //     this.$bind('co', db.collection("organisations").doc(theCurrentUser.organisationId).get())
    //     this.$bind('ou', db.collection("users").where("organisationId", "==", theCurrentUser.organisationId))
    //   },
    // },
    usersList: {
      handler () {
        // this.getAllUserInfo(this.currentUser.organisationId) //comment to test call rate
      },
      deep:true,
    },
    options: {
      handler () {
        // this.getDataFromApi()
      },
      deep: true,
    },
  },

  mounted () {
    // this.getAllUserInfo(this.currentUser.organisationId)
    // this.getDataFromApi()
    //   .then(data => {
    //     this.users = data.items
    //     this.totalUsers = data.total
    //   })
  },

  firestore() {

    // this.$bind('cu', db.collection("users").doc(auth.currentUser.uid).get())
    // this.$bind('co', db.collection("organisations").doc(this.currentUser.organisationId).get())
    // this.$bind('ou', db.collection("users").where("organisationId", "==", this.currentUser.organisationId))

    // get the current user
    // this.$bind('boundUserList',
    db.collection(USERS).doc(auth.currentUser.uid).get()
      .then((user) => {
        this.currentUser = user.data()

        // get organisation if they have one
        if (this.currentUser.organisationId) {
          db.collection(ORGANISATIONS).doc(this.currentUser.organisationId).get()
          .then((org) => {
            this.currentOrganisation = org.data();
            // get users for organisation
            db.collection(USERS)
              .where("organisationId", "==", this.currentUser.organisationId) // auth.currentUser.organisationId)
              .onSnapshot(
                (querySnapshot) => {
                  const documents = querySnapshot.docs.map((doc) => {
                    const item = doc.data();
                    item.id = doc.id;
                    if (item.reportsGenerated === undefined) {
                      item.reportsGenerated = 0;
                    }
                    if (item.organisation === undefined) {
                      item.organisation = this.currentOrganisation
                    }

                    // const matched = allUserInfo.data.find((user) => {
                    //   return (user.uid === item.id)
                    // })

                    // if (!matched) {
                    //   return
                    // } else {
                    //   item.name = matched.displayName
                    // }

                    // if (allUserInfo.contains(item.id)){
                    //   console.log("found a match")
                    //   item.name = allUserInfo.displayName
                    // }
                    // const userData = this.getUserInfo(item.id);
                    // console.log("item is " + item)
                    // console.log("userdata is "+ userData);
                    // item.name = userData.displayName;
                    return item;
                  });


                  // documents.map(() => {

                  // })
                  // const matched = allUserInfo.data.find((user) => {
                  //     return (user.uid === item.id)
                  // })

                  // if (!matched) {
                  //   return
                  // } else {
                  //   item.name = matched.displayName
                  // }

                  // this.$bind('boundUserList', documents);
                  this.usersList = documents;

                  // this.usersList.map((auser) => {
                  //   const matched = allUserInfo.data.find((user) => {
                  //       return (user.uid === auser.id)
                  //   })

                  //   if (!matched) {
                  //     return
                  //   } else {
                  //     auser.name = matched.displayName
                  //   }
                  //   return auser;
                  // })
                  // this.$bind('boundUserList', this.usersList);
                },
                (error) => {
                  // eslint-disable-next-line no-console
                  console.error("Error fetching documents: ", error);
                }
              );

          }).catch((error) => {
            // eslint-disable-next-line no-console
            console.error("Error getting org document: ", error);
          });
        }
      }).catch((error) => {
        // eslint-disable-next-line no-console
        console.error("Error getting user document: ", error);
      })
    // );
  },

  created() {
    // this.$store.dispatch("retrieveRoles");
    this.$store.dispatch("retrieveSubscriptions");
    this.$store.dispatch("retrieveCredits");
    this.setAvatar();
  },

  methods: {
    getUserName: function(uid){
      if (this.user.user.uid === uid) {
        return this.user.user.displayName
      }

      const matched = this.adminUserList.data.find((user) => {
        return (user.uid === uid)
      })

      if (!matched) {
        return ""
      }
      return matched.displayName
    },
    getDataFromApi () {
      this.userLoading = true
      this.getFirebaseUsers().then(data => {
        this.users = data.items
        this.totalUsers = data.total
        this.userLoading = false
      })
    },
    getFirebaseUsers: async function() {
      // return new Promise((resolve, reject) => {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options

        // let items = this.usersList
        let items = this.usersList
        // let items = this.ou
        // this.getUsers().then((theUsersList) => {
          // let items = this.boundUserList; // theUsersList

          const total = items.length

          if (sortBy.length === 1 && sortDesc.length === 1) {
            items = items.sort((a, b) => {
              const sortA = a[sortBy[0]]
              const sortB = b[sortBy[0]]

              if (sortDesc[0]) {
                if (sortA < sortB) return 1
                if (sortA > sortB) return -1
                return 0
              } else {
                if (sortA < sortB) return -1
                if (sortA > sortB) return 1
                return 0
              }
            })
          }

          if (itemsPerPage > 0) {
            items = items.slice((page - 1) * itemsPerPage, page * itemsPerPage)
          }

          setTimeout(() => {
            Promise.resolve({
              items,
              total,
            })
          }, 1000)
        // })
      // })
    },
    getUsers2(){
      return this.boundUserList
    },
    getUsers: async function() {
      let newUserList;
      // get the current user
      db.collection(USERS).doc(auth.currentUser.uid).get()
        .then((user) => {
        this.currentUser = user.data()

        // get organisation if they have one
        db.collection(ORGANISATIONS).doc(this.currentUser.organisationId).get()
          .then((org) => {
            this.currentOrganisation = org.data();

            // const data = await this.getAllUserInfo(item.id)
        // get users for organisation
        db.collection(USERS)
          .where("organisationId", "==", this.currentUser.organisationId) // auth.currentUser.organisationId
          .onSnapshot(
            (querySnapshot) => {
              const documents = querySnapshot.docs.map((doc) => {
                const item = doc.data();
                item.id = doc.id;
                if (item.reportsGenerated === undefined) {
                  item.reportsGenerated = 0;
                }
                if (item.organisation === undefined) {
                  item.organisation = this.currentOrganisation
                }
                // if (allUserInfo.contains(item.id)){
                //   console.log("found a match")
                //   item.name = allUserInfo.displayName
                // }

                // const data = await this.getUserInfo(item.id)
                // const userData = this.getUserInfo(item.id);
                // console.log("item is " + item)
                // console.log("userdata is "+ userData);
                // item.name = userData.displayName;
                return item;
              });
              newUserList = documents;
            },
            (error) => {
              // eslint-disable-next-line no-console
              console.error("Error fetching documents: ", error);
            }
          );

          }).catch((error) => {
            // eslint-disable-next-line no-console
            console.error("Error getting org document: ", error);
          });

      }).catch((error) => {
        // eslint-disable-next-line no-console
        console.error("Error getting user document: ", error);
      })
      return newUserList;
    },
    deleteOrganisationUser : async function(item) {
      if (confirm("Are you sure you want to delete this user?")) {
        const functionLocation = "australia-southeast1"; // us-central1, for example
        const functionRef = isRunningWithEmulator
          ? firebase // for emulator
              .app()
              .functions()
              .httpsCallable("deleteOrganisationUser")
          : firebase // for prod
              .app()
              .functions(functionLocation)
              .httpsCallable("deleteOrganisationUser");
        await functionRef({
          email: item.email,
          organisationId: this.currentUser.organisationId
        })
        .then( (callResult) => {
          // alert(`Delete called for ${JSON.stringify(callResult)}`)
          this.showMessage(`Deleted User ${callResult.data.displayName}`, "success");
        })
        .catch( (error) => {
          // Getting the Error details.
          // var code = error.code;
          var message = error.message;
          // var details = error.details;
          // alert(`Error occured creating the user (${code}) - ${message}`)
          this.showMessage(`Error occured deleting the user: ${message}`, "error");
        });
      }
    },
    showMessage: function (message, color) {
      this.snackbarColor = color;
      this.snackbar = true;
      this.message = message;
    },
    getAllUserInfo: async function (organisationId) {
      const functionLocation = "australia-southeast1"; // us-central1, for example
      const functionRef = isRunningWithEmulator
        ? firebase // for emulator
            .app()
            .functions()
            .httpsCallable("listOrganisationUsers")
        : firebase // for prod
            .app()
            .functions(functionLocation)
            .httpsCallable("listOrganisationUsers");
      await functionRef({
        organisationId: organisationId,
      })
      .then( (callResult) => {
        // alert(`Delete called for ${JSON.stringify(callResult)}`)
        // this.showMessage(`Retrieve User with email ${callResult.data.email}`, "primary");
        // console.log("output all users " + JSON.stringify(callResult))
        this.adminUserList = callResult;
        return callResult
      })
      .catch( (error) => {
        // Getting the Error details.
        // var code = error.code;
        var message = error.message;
        // var details = error.details;
        // alert(`Error occured creating the user (${code}) - ${message}`)
        this.showMessage(`Retrieve All User Errors: ${message}`, "error");
      });
    },
    getUserInfo: async function (userUid) {
      const functionLocation = "australia-southeast1"; // us-central1, for example
      const functionRef = isRunningWithEmulator
        ? firebase // for emulator
            .app()
            .functions()
            .httpsCallable("getOrganisationUser")
        : firebase // for prod
            .app()
            .functions(functionLocation)
            .httpsCallable("getOrganisationUser");
      await functionRef({
        uid: userUid,
      })
      .then( (callResult) => {
        // alert(`Delete called for ${JSON.stringify(callResult)}`)
        // this.showMessage(`Retrieve User with email ${callResult.data.email}`, "primary");
        return callResult
      })
      .catch( (error) => {
        // Getting the Error details.
        // var code = error.code;
        var message = error.message;
        // var details = error.details;
        // alert(`Error occured creating the user (${code}) - ${message}`)
        this.showMessage(`Retrieve User Error: ${message}`, "error");
      });
    },
    verifyEmail: function (user) {
      const doVerify = confirm(
        `Would you like to force a resend of email verification to ${user.email}?`
      );
      if (doVerify) {
        firebase
          .auth()
          .currentUser.sendEmailVerification({
            url: window.location.origin + "/#/pages/user",
          })
          .then(() => {
            // Email verification sent!
            alert(
              "Verification Email has been sent please check your email inbox"
            );
          })
          .catch((error) => {
            alert(
              "An error occurred when attempting the email verification to be sent. " +
                error
            );
          });
      }
    },
    async openStripePortal(value) {
      this.overlay = true;
      this.loader = "loading";
      this.loading = true;

      // Billing portal handler
      const functionLocation = "australia-southeast1"; // us-central1, for example
      // Call billing portal function
      const functionRef = isRunningWithEmulator
        ? firebase // for emulator
            .app()
            .functions()
            .httpsCallable("createPortalLink")
        : firebase // for prod
            .app()
            .functions(functionLocation)
            .httpsCallable("createPortalLink");
      const { data } = await functionRef({
        returnUrl: window.location.origin + "/#/pages/user",
      });
      this.loader = null;
      this.loading = true;
      this.overlay = false;
      window.location.assign(data.url);
    },
    async saveUploadedImage() {
      const file = this.avatarImage;
      const fileName = this.uploadedFile;
      const userId = auth.currentUser.uid;

      try {
        // save avatar URL to users document
        const user = db.collection(USERS).doc(userId);

        const filePath = `users/${userId}/${fileName.name}`;
        await user.update({
            avatarUrl: filePath
        })

        // save image to firebase storage
        const userAvatarStorageRef = storageRef.child(filePath);
        await userAvatarStorageRef.putString(file, 'data_url');

        this.showMessage("Successfully uploaded user avatar");

        this.clearUploadedImage();
        this.avatarImage = file;
      } catch (error) {
        var message = error.message;
        this.showMessage(`Error encountered while uploading user avatar: ${message}`, "error");
      }
    },
    clearUploadedImage() {
      this.setAvatar();
      this.uploadedFile = null;
      this.uploadedImage = null;
    },
    async setAvatar() {
      const user = await db.collection(USERS).doc(auth.currentUser.uid).get();
      const avatarUrl = user.data().avatarUrl;
      this.avatarImage = defaultAvatarImage;

      if (avatarUrl) {
        const logoRef = storageRef.child(avatarUrl);
        this.avatarImage = await logoRef.getDownloadURL();
      }
    },
    async uploadImage(event) {
      if (!event) return;
      var file = event;
      this.uploadedImage = await this.toBase64(file);
      this.$refs.cropperDialog.initCropper(file.type);
    },
    async toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    },
    onAvatarClicked() {
      this.$refs.imageField.$refs.input.click();
    }
  },
};
</script>

<style scoped>
.action-btn {
  background-color: #fff !important;
}


.file-upload-field {
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 5px;
}

.file-upload-field:hover {
  border: 1px solid #333;
}
</style>